/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
/* App imports */
import TagList from '../tag-list'
import Utils from '../../utils'
import {container} from './post-list.module.less' 
import {postDesign} from './post-list.module.less' 
import {coverDesign} from './post-list.module.less' 
import {content} from './post-list.module.less' 

const PostList = ({ posts }) => (
  <div className={container}>
    {posts.map((post, index) => {
      const { title, date, path, tags, cover, excerpt } = post.node.frontmatter
      let pathLink = '/' + path
      return (
        <div key={title} className={postDesign}>
          <div className={coverDesign}>
            <Link to={pathLink}>
              <Img
                fluid={cover.childImageSharp.fluid}
                title={excerpt}
                alt={title}
              />
            </Link>
          </div>
          <div className={content}>
            <Link to={pathLink}>
              {date ? <label>{date}</label> : null}
              <h2>{title}</h2>
              <p>{excerpt}</p>
            </Link>
            <TagList tags={tags} />
          </div>
        </div>
      )
    })}
  </div>
)

//<Link to={Utils.resolvePageUrl(path)}>

PostList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          title: PropTypes.string.isRequired,
          date: PropTypes.string,
          path: PropTypes.string.isRequired,
          tags: PropTypes.arrayOf(PropTypes.string).isRequired,
          cover: PropTypes.shape({
            childImageSharp: PropTypes.shape({
              fluid: PropTypes.object.isRequired,
            }).isRequired,
          }).isRequired,
        }),
      }),
    })
  ),
}

export default PostList
