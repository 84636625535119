/* Vendor imports */
import React from 'react'
import PropTypes from 'prop-types'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
/* App imports */
import Button from '../../components/button'
import Config from '../../../config'
import Utils from '../../utils'
import {container} from './archive-pagination.module.less'
import {buttonLeft} from './archive-pagination.module.less'
import {buttonRight} from './archive-pagination.module.less'

//Utils.resolvePageUrl(Config.pages.archive, prevPage)
//Utils.resolvePageUrl(Config.pages.archive, nextPage)
const ArchivePagination = ({ prevPage, nextPage }) => (
  <div className={container}>
    {prevPage ? (
      <Button
        to={'/' + Config.pages.archive + '/' + prevPage}
        buttonStyle={buttonLeft}
      >
        <FaArrowLeft />
        <span>最近の投稿</span>
      </Button>
    ) : null}
    {nextPage ? (
      <Button
        to={'/' + Config.pages.archive + '/' + nextPage}
        buttonStyle={buttonRight}
      >
        <span>過去の投稿</span>
        <FaArrowRight />
      </Button>
    ) : null}
  </div>
)

ArchivePagination.propTypes = {
  prevPage: PropTypes.number,
  nextPage: PropTypes.number,
}

export default ArchivePagination
